.floatBtnContent {
  text-align: center;
  z-index: 1000;
  width: calc(100% - 62px);
  height: 54px;
  bottom: 15px;
}

.content {
  color: var(--app-text-color);
  text-align: center;
  margin-bottom: 184px;
  // height: 300px;
  padding: 50px 0;
  background: linear-gradient(180deg, #1d1b2d 0%, #5444ba 100%);
  // background-image: url('/images/home/sign-up-icon.webp');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 28px;

  > .title {
    font-size: 36px;
    font-weight: bold;
    color: #ffffff;
    line-height: 50px;
  }

  > .desc {
    font-size: 20px;
    font-weight: 400;
    color: #ffffff;
    line-height: 28px;
    padding-top: 12px;
    padding-left: 20px;
    padding-right: 20px;
  }

  > .signUpBtn {
    height: 54px;
    border-radius: 27px;
    margin-top: 42px;
    font-size: 16px;
    color: #ffffff;
    padding: 0 60px;
  }
}

@media screen and (min-width: 899px) {
  .floatBtnContent {
    display: none;
  }
  .content {
    display: block;
    margin-bottom: 164px;
  }
}

@media (max-width: 900px) and (min-width: 768px) {
  .floatBtnContent {
    display: none;
  }
  .content {
    display: block;
    margin-bottom: 134px;
  }
}

@media (max-width: 769px) and (min-width: 576px) {
  .floatBtnContent {
    display: none;
  }
  .content {
    display: block;
    margin-bottom: 100px;
  }
}

@media screen and (max-width: 577px) {
  .floatBtnContent {
    display: block;
  }
  .content {
    display: none;
    margin-bottom: 50px;
  }
}

